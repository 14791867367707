import React from 'react';
import './Header.style.css';

const Header = () => {
  return (
    <header className="Header">
      <a href="#">H + O</a>
    </header>
  );
};

export default Header;
