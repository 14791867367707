import React from 'react';
import './Banner.style.css';

const Banner = () => {
  return (
    <section className="Banner">
      <h2>Check back here soon for pictures from the wedding!</h2>
    </section>
  );
};

export default Banner;
